const meta = {
  // Metadata
  // Finds usage in gatsby-config, i18n, and SEO component
  siteTitle: 'David John - Full Stack Developer & Designer',
  siteDescription: `Hi! I'm David and I'm a passionate user interface designer & developer. I design, create and develop cross-platform design concepts to get the most out of your brand or business.`,
  siteHeadline: 'Full Stack Developer & Designer',
  siteTitleAlt: 'David John',
  siteShortName: 'David',
  siteUrl: 'https://www.davidjohn.pro', // No trailing slash!
}

const social = {
  siteLogo: `${meta.siteUrl}/social/avatar.png`,
  siteLogoSmall: `${meta.siteUrl}/social/avatar_small.png`,
  siteBanner: `${meta.siteUrl}/social/banner_`, // Locale ending + filetype gets added in SEO component
  siteBannerWidth: '776',
  siteBannerHeight: '382',
  twitter: '@davidjohn_sdj',
  facebook: 'davidjohn.pro',
}

const website = {
  ...meta,
  ...social,
  googleAnalyticsID: 'UA-21417687-1',

  // Manifest
  themeColor: '#3498DB',
  backgroundColor: '#2e3246',
}

module.exports = website
