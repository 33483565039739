const website = require('./website')

module.exports = {
  'en-us': {
    default: true,
    path: 'en',
    locale: 'en-us',
    htmlLang: 'en',
    ogLang: 'en_US',
    dateFormat: 'dd-MMM-yyyy',
    siteTitle: website.siteTitle,
    siteDescription: website.siteDescription,
    siteHeadline: website.siteHeadline,
    projects: 'Projects',
    contact: 'Contact',
  }
  // 'de-de': {
  //   path: 'de',
  //   locale: 'de-de',
  //   htmlLang: 'de',
  //   ogLang: 'de_DE',
  //   dateFormat: 'dd.MM.yyyy',
  //   siteTitle: website.siteTitle,
  //   siteDescription: website.siteDescription,
  //   siteHeadline: website.siteHeadline,
  //   projects: 'Projekte',
  //   contact: 'Kontakt',
  // },
}
